body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ContentWrap {
  margin: 15px auto;
  padding: 0px 0.8rem;
  display: flex;
  align-self: flex-start;
  -webkit-box-align: stretch;
  align-items: stretch;
}

MiniGameContent {
  z-index: 10;
  position: relative;
  min-height: 650px;
  width: 100%;
}

SportGameContentH1 {
  font: 26px "bootleregular", "Nanum Gothic";
  font-size: 26px;
  line-height: 28px;
  color: #fff;
  padding-bottom: 5px;
  border-bottom: 1px solid #666;
  margin-top: 10px;
}

SportGameContentSlider {
  font-size: 9px;
  transform: translate3d(0px, 0px, 0px);
  flex-direction: row;
  margin-bottom: 10px;
}

SportGameContentSliderA {
  display: inline-block;
  color: rgb(156, 155, 155);
  flex-shrink: 0;
  text-decoration: none;
}

SliderContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

SportGameContentSliderABtn {
  font-size: 12px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  font-size: 0.75rem;
  cursor: pointer;
  user-select: none;
  background-clip: padding-box;
  letter-spacing: 1px;
  white-space: nowrap;
  background-color: rgb(45, 46, 53);
  color: rgb(205, 205, 204);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 4px,
    rgba(255, 255, 255, 0.22) 1px 1px 4px -2px inset;
  fill: rgb(0, 0, 0);
  height: 30px;
  outline: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-image: initial;
  transition: background-color 0.2s ease 0s, color 0.2s ease 0s,
    border-color 0.2s ease 0s;
  padding: 0px 10px;
  border-radius: 5px;
}

SportGameContentSliderABtnText {
  text-transform: uppercase;
  line-height: 1;
  text-align: left;
  font-size: 12px;
}

MiniGameWindow {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.3rem;
  background-color: rgb(31, 29, 20);
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(255, 210, 0);
  border-image: initial;
  border-radius: 5px;
}

/*  1920센터 정렬 완벽 구현 */
.slideshow5_wrap {
  position: relative;
  height: 400px;
  top: 0px;
  overflow: hidden;
  clear: both;
  border-bottom: 5px solid rgba(0, 0, 0, 0.3);
}
.slideshow5_wrap_center {
  position: absolute;
  width: 1920px;
  left: 50%;
  margin-left: -960px;
}

/*  화살표 스타일1 */
.arrow_wrap {
  position: absolute;
  width: 1600px;
  left: 50%;
  margin-left: -800px;
  top: 200px;
  z-index: 10000;
} /* 화살표 위치구현 */
.arrow_left {
  float: left;
}
.arrow_right {
  float: right;
}

/*  화살표 스타일2 */
.wrap_btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100px;
  z-index: 110;
  margin-left: -50px;
} /* 화살표 위치구현 */
.slide1 {
  position: relative;
}
.wrap_btn span {
  float: left;
  cursor: pointer;
}

/*  점 스타일 */
.slide_pager {
  position: absolute;
  left: 50%;
  top: 330px;
  width: 500px;
  z-index: 110;
  margin-left: -49px;
} /* 점의 위치설성 */
.slide_pager span {
  font-size: 65px;
  float: left;
  width: 35px;
  color: #969696;
  cursor: pointer;
  text-align: left;
} /* 점의 기본 색상 */
.slide_pager span.cycle-pager-active {
  color: #0bf6f7;
} /* 점의 선택시 색상 */
